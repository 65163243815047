import React from 'react'
import { Label } from 'semantic-ui-react'
import { store } from './store'

const AUDIT_START = 'audit-start'
const NAVIGATION = 'navigation'
const AUDITOR_ACK = 'auditor-ack'
const AUDITOR_ACK_REQUESTED = 'auditor-ack-requested'

export function toComponent(message) {
  const actor = store.getState().app.socket.initiator
    ? store.getState().app.auth.username
    : store.getState().app.socket.peerName
  const auditor = store.getState().app.socket.initiator
    ? store.getState().app.socket.peerName
    : store.getState().app.auth.username
  switch (message.type) {
    case AUDIT_START:
      return (
        <>
          {auditor} hat als Auditor den{' '}
          <Label color="purple">Audit gestartet</Label>
        </>
      )
    case NAVIGATION:
      return (
        <>
          {actor} navigiert zu <Label color="purple">{message.to}</Label>
        </>
      )
    case AUDITOR_ACK:
      return (
        <>
          {auditor} hat die Aktion{' '}
          <Label color="purple">{message.operation}</Label> bestätigt
        </>
      )
    case AUDITOR_ACK_REQUESTED:
      return (
        <>
          {actor} bittet {auditor} um Bestätigung der Aktion{' '}
          <Label color="purple">{message.operation}</Label>
        </>
      )
    default:
      console.warn('unknown message', message)
      return ''
  }
}

export function auditStart(auditor) {
  return {
    type: AUDIT_START,
    auditor
  }
}

export function auditNavigation(to) {
  return {
    type: NAVIGATION,
    to
  }
}

export function auditAuditorAck(operation) {
  return {
    type: AUDITOR_ACK,
    operation
  }
}

export function requestAuditorAck(operation) {
  return {
    type: AUDITOR_ACK_REQUESTED,
    operation
  }
}
