import produce from 'immer'

import initialState from './initial-state'
import {
  TOGGLE_ALL_CARDS_SELECTED,
  TOGGLE_CARD_SELECTION,
  INVERT_CARD_SELECTION,
  WAIT_FOR_APPROVAL,
  NEXT_STAGE,
  RESET,
  FINISH_CARD
} from './actions'

export default function(
  state = initialState,
  action = { type: '', payload: undefined }
) {
  if (action.type === RESET) {
    return initialState
  }

  return produce(state, draft => {
    switch (action.type) {
      case TOGGLE_ALL_CARDS_SELECTED:
        if (state.cards.length !== state.selectedCards.length) {
          draft.selectedCards = state.cards
        } else {
          draft.selectedCards = []
        }
        return
      case TOGGLE_CARD_SELECTION:
        if (state.selectedCards.includes(action.payload.card)) {
          draft.selectedCards = state.selectedCards.filter(
            card => card !== action.payload.card
          )
        } else {
          draft.selectedCards.push(action.payload.card)
        }
        return
      case INVERT_CARD_SELECTION:
        draft.selectedCards = state.cards.filter(
          card => !state.selectedCards.includes(card)
        )
        return
      case WAIT_FOR_APPROVAL:
        draft.waitForApproval = true
        return
      case NEXT_STAGE:
        draft.stage = nextStage(state.stage)
        return
      case FINISH_CARD:
        draft.finishedCards.push(
          state.cards.find(card => card.orderId === action.payload.card.orderId)
        )
        return
      default:
        return state
    }
  })
}

function nextStage(currentStage) {
  if (currentStage === 'card-present') return 'select-cards'
  if (currentStage === 'select-cards') return 'acknowledge-selection'
  if (currentStage === 'acknowledge-selection') return 'sign-device-ids'
  if (currentStage === 'sign-device-ids') return 'display-protocol'
  return 'nyi'
}
