import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import NotFound from './404'

const Main = ({ routes, children }) => (
  <main>
    <Suspense fallback={<Loader active size="massive" />}>
      <Switch>
        {routes.map(route => (
          <Route
            exact={route.exact}
            path={route.path}
            component={route.component}
            key={route.name}
          />
        ))}
        <NotFound />
      </Switch>
    </Suspense>
    {children}
  </main>
)

export default Main
