export const TOGGLE_CARD_SELECTION =
  '@@app/chambercard-authorization/TOGGLE_CARD_SELECTION'
export const INVERT_CARD_SELECTION =
  '@@app/chambercard-authorization/INVERT_CARD_SELECTION'
export const TOGGLE_ALL_CARDS_SELECTED =
  '@@app/chambercard-authorization/TOGGLE_ALL_CARDS_SELECTED'
export const WAIT_FOR_APPROVAL =
  '@@app/chambercard-authorization/WAIT_FOR_APPROVAL'
export const NEXT_STAGE = '@@app/chambercard-authorization/NEXT_STAGE'
export const RESET = '@@app/chambercard-authorization/RESET'
export const FINISH_CARD = '@@app/chambercard-authorization/FINISH_CARD'

export function toggleCardSelection(card) {
  return {
    type: TOGGLE_CARD_SELECTION,
    payload: {
      card
    }
  }
}

export function invertCardSelection() {
  return {
    type: INVERT_CARD_SELECTION
  }
}

export function toggleAllCardsSelected() {
  return {
    type: TOGGLE_ALL_CARDS_SELECTED
  }
}

export function nextStage() {
  return {
    type: NEXT_STAGE
  }
}

export function waitForApproval() {
  return {
    type: WAIT_FOR_APPROVAL
  }
}

export function reset() {
  return {
    type: RESET
  }
}

export function finishCard(card) {
  return {
    type: FINISH_CARD,
    payload: {
      card
    }
  }
}
