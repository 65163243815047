import initialState from './initial-state'
import {
  NEXTPAGE,
  CUSTODIAN_START,
  CUSTODIANS_BEGIN,
  RESET,
  CUSTODIAN_FINISH,
  CUSTODIANS_DONE,
  BACKUP_MODE,
  SET_DKEK
} from './actions'

export default function(
  state = initialState,
  action = { type: '', payload: {} }
) {
  switch (action.type) {
    case RESET:
      return initialState
    case NEXTPAGE:
      return {
        ...state,
        page: nextPage(state.page, state.backup) || state.page
      }
    case CUSTODIANS_BEGIN:
      return {
        ...state,
        custodians: action.payload.custodians.map(name => ({
          name,
          status: 'todo'
        }))
      }
    case CUSTODIAN_START:
      return {
        ...state,
        custodians: state.custodians.map(c =>
          c.name === action.payload.name
            ? {
                name: c.name,
                status: 'in-progress'
              }
            : c
        )
      }
    case CUSTODIAN_FINISH:
      return {
        ...state,
        custodians: state.custodians.map(c =>
          c.name === action.payload.name
            ? {
                name: c.name,
                status: 'done'
              }
            : c
        )
      }
    case CUSTODIANS_DONE:
      return {
        ...state,
        custodiansDone: true
      }
    case BACKUP_MODE:
      return {
        ...state,
        backup: true
      }
    case SET_DKEK:
      return {
        ...state,
        dkek: action.payload.dkek
      }
    default:
      return state
  }
}

function nextPage(currentPage, backupMode) {
  switch (currentPage) {
    case 'card-ready':
      return backupMode ? 'enter-dkek' : 'ready'
    case 'enter-dkek':
      return 'ready'
    case 'ready':
      return 'import-custodians'
    case 'import-custodians':
      return 'setup-card-system'
    case 'setup-card-system':
      return backupMode ? 'display-protocol' : 'store-dkek'
    case 'store-dkek':
      return 'display-protocol'
    case 'display-protocol':
      return backupMode ? null : 'encourage-backup'
    case 'encourage-backup':
      return null
    default:
      throw new Error('unknown page: ' + currentPage)
  }
}
