const cards = [
  {
    orderId: '8336',
    chamber: 'Frankfurt am Main',
    cardId: '4320 810023 99383 851',
    createdAt: '29.08.2019, 13:36:20'
  },
  {
    orderId: '6622',
    chamber: 'Schleswig-Holstein',
    cardId: '1136 685769 28487 3072',
    createdAt: '04.09.2019, 02:56:14'
  },
  {
    orderId: '2916',
    chamber: 'Berlin',
    cardId: '7675 243415 31218 8611',
    createdAt: '30.08.2019, 17:42:34'
  },
  {
    orderId: '1787',
    chamber: 'Brandenburg',
    cardId: '3023 42542 28427 8045',
    createdAt: '28.08.2019, 21:33:12'
  },
  {
    orderId: '3754',
    chamber: 'Brandenburg',
    cardId: '4587 384614 40234 4147',
    createdAt: '01.09.2019, 08:03:50'
  },
  {
    orderId: '7813',
    chamber: 'Frankfurt am Main',
    cardId: '8148 629965 97321 8299',
    createdAt: '30.08.2019, 17:11:28'
  },
  {
    orderId: '5825',
    chamber: 'Rheinland',
    cardId: '8177 368739 16405 3819',
    createdAt: '30.08.2019, 08:53:51'
  },
  {
    orderId: '8018',
    chamber: 'Baden-Württemberg',
    cardId: '6997 984591 30780 131',
    createdAt: '02.09.2019, 22:22:35'
  },
  {
    orderId: '5098',
    chamber: 'Schleswig-Holstein',
    cardId: '4481 383914 31869 6016',
    createdAt: '28.08.2019, 17:45:56'
  },
  {
    orderId: '3415',
    chamber: 'Schleswig-Holstein',
    cardId: '5276 379717 52182 3812',
    createdAt: '02.09.2019, 03:06:38'
  }
].sort((a, b) => a.chamber.localeCompare(b.chamber))

export default {
  stage: 'card-present',
  cards,
  selectedCards: [],
  waitForApproval: false,
  finishedCards: []
}
