import React, { useEffect } from 'react'

import { AppHeader, UserAccount, CircleIcon } from '@procilon/react-components'
import './Header.less'
import logoUrl from 'assets/logo.svg'
import { logout, toggleSidebar, toggleAuditSidebar } from 'state/actions'
import { store } from 'state/store'
import { Icon, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import useAudit from 'hooks/useAudit'

const profileMenuItems = [
  {
    text: 'Logout',
    handleClick() {
      store.dispatch(logout())
    }
  }
]

function patchTitle({ requestedPeerName, initiator, state }) {
  let titleType = 'normal'

  if (requestedPeerName) {
    titleType = 'peer requested'
  }

  if (
    !initiator &&
    state.acknowledgements.inProgress &&
    !state.acknowledgements.auditorAck
  ) {
    titleType = 'action requested'
  }

  switch (titleType) {
    case 'peer requested':
      if (!document.title.startsWith('(')) {
        document.title = `(1) ${document.title}`
      }
      break
    case 'action requested':
      if (!document.title.startsWith('(')) {
        document.title = `(!) ${document.title}`
      }
      break
    default:
      if (document.title.startsWith('(')) {
        document.title = document.title.slice(4)
      }
  }
}

function Header({
  sidebarCollapsed,
  toggleSidebar,
  userid,
  peerId,
  initiator,
  requestedPeerName
}) {
  const { state } = useAudit()

  useEffect(() => {
    patchTitle({ initiator, state, requestedPeerName })
  })

  return (
    <header>
      <AppHeader
        logoUrl={logoUrl}
        shopIcon={
          <button
            className="proc_auditor-button"
            onClick={() => store.dispatch(toggleAuditSidebar())}
          >
            {peerId ? (
              <CircleIcon content={<Icon name="eye" />} />
            ) : (
              <CircleIcon
                content={<Icon name="eye slash" />}
                label={
                  requestedPeerName && (
                    <Label floating size="mini" color="purple">
                      !
                    </Label>
                  )
                }
              />
            )}
          </button>
        }
        sidebarToggle={sidebarCollapsed ? 'expand' : 'collapse'}
        onClickSidebarToggle={toggleSidebar}
        userAccount={
          // TODO content not correct
          <UserAccount
            loggedIn
            text={userid}
            iconContent={userid.charAt(0).toUpperCase()}
            menuItems={profileMenuItems}
          />
        }
      />
    </header>
  )
}

function mapStateToProps(state) {
  return {
    sidebarCollapsed: state.app.sidebarCollapsed,
    userid: state.app.auth.username,
    acknowledgements: state.app.acknowledgements,
    ...state.app.socket
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSidebar: () => dispatch(toggleSidebar())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
