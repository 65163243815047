export const NEXT_STAGE = '@@app/chamber-card-creation/NEXT_STAGE'
export const GOTO_STAGE = '@@app/chamber-card-creation/GOTO_STAGE'
export const CHOOSE_CHAMBER = '@@app/chamber-card-creation/CHOOSE_CHAMBER'
export const RESET = '@@app/chamber-card-creation/RESET'
export const WAIT_FOR_APPROVAL = '@@app/chamber-card-creation/WAIT_FOR_APPROVAL'

export function reset() {
  return {
    type: RESET
  }
}

export function nextStage() {
  return {
    type: NEXT_STAGE
  }
}

export function gotoStage(stage) {
  return {
    type: GOTO_STAGE,
    payload: {
      stage
    }
  }
}

export function chooseChamber(chamber) {
  return {
    type: CHOOSE_CHAMBER,
    payload: {
      chamber
    }
  }
}

export function waitForApproval() {
  return {
    type: WAIT_FOR_APPROVAL
  }
}
