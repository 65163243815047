import { store } from './store'
import {
  ACKNOWLEDGE,
  PEER_DISCONNECTED,
  resetSocket,
  availablePeers,
  login,
  audit
} from './actions'
import { auditStart } from './auditMessages'
import { info } from 'react-toastify-redux'
import { NOBODY, ALL_USERNAMES, randomUser } from './initial-state'
import { push } from 'connected-react-router'

// const wsUri = 'ws://localhost:8080/websocket-foo-0.0.1-SNAPSHOT/ws'
const wsUri = 'wss://bnotk01.westeurope.cloudapp.azure.com/ws'

let websocket = createSocket()

function createSend(websocket) {
  return (...args) => {
    if (args[0] !== 'users') {
      console.log('send', ...args)
    }
    if (websocket.ready) {
      websocket.send(...args)
    } else {
      websocket.addEventListener('open', () => websocket.send(...args))
    }
  }
}

export let send = createSend(websocket)

setInterval(() => send('users'), 1000)

export function reset() {
  websocket.close()
  const action = resetSocket()
  action._socket = true
  store.dispatch(action)
  websocket = createSocket()
  send = createSend(websocket)
}

let connectedAsClient = false
export function isConnectedAsClient() {
  return connectedAsClient
}

function createSocket() {
  const websocket = new WebSocket(wsUri)
  console.log('new socket')

  websocket.addEventListener('open', e => {
    console.log('connected')
    websocket.send('users')
    websocket.ready = true
  })
  websocket.onclose = e => console.log('disconnect')
  websocket.onerror = e => console.error('error', e)

  websocket.onmessage = e => {
    if (!e.data.startsWith('users:')) {
      console.log('msg', e.data)
    }
    if (e.data.startsWith('negotiation:')) {
      const x = JSON.parse(e.data.substring('negotiation:'.length))
      if (x.type === 'REQUEST') {
        store.dispatch({
          type: 'SOCKET_REQUESTED',
          payload: {
            peerName: x.data,
            peerId: x.peerId
          }
        })
      } else if (x.type === 'ACCEPTED') {
        const action = {
          type: 'SOCKET_ACCEPTED',
          payload: {
            peerId: x.peerId,
            initiator: !!x.data.initiator
          }
        }
        if (x.data.peerName) {
          action.payload.peerName = x.data.peerName
        }
        store.dispatch(action)
        store.dispatch(audit(auditStart(x.data.peerName)))
        connectedAsClient = !x.data.initiator
        if (
          store.getState().chamberCardCreation.stage !== 'start-audit' &&
          store.getState().router.location.pathname !== '/chamber-auth'
        ) {
          store.dispatch(push('/'))
        }
      }
    } else if (e.data === 'ack') {
      store.dispatch({
        type: ACKNOWLEDGE,
        payload: {
          operation: store.getState().app.acknowledgements.operation
        },
        _socket: true
      })
    } else if (e.data === 'peer disconnected') {
      store.dispatch({
        type: PEER_DISCONNECTED,
        _socket: true
      })
      store.dispatch(
        info('Verbindung wurde getrennt', {
          position: 'top-center'
        })
      )
    } else if (e.data.startsWith('users:')) {
      const users = JSON.parse(e.data.substring('users:'.length))
      const appState = store.getState().app
      if (
        JSON.stringify(users) !== JSON.stringify(appState.socket.availablePeers)
      ) {
        store.dispatch(availablePeers(users))

        if (appState.auth.username === NOBODY) {
          const offlineUsers = ALL_USERNAMES.filter(
            name => !users.some(user => user.name === name)
          )
          const username = randomUser(offlineUsers)
          store.dispatch(login('token', username))
          websocket.send(`user:${username}`)
        }
      }
    } else {
      const action = JSON.parse(e.data)
      action._socket = true
      if (
        action.type === 'SOCKET_SESSION_ID' ||
        !store.getState().app.socket.initiator
      ) {
        store.dispatch(action)
      }
    }
  }

  return websocket
}
