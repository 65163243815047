import React from 'react'
import { AppSidebar } from '@procilon/react-components'
import { withRouter, matchPath } from 'react-router-dom'

import './Menu.less'

function findMatchingRoute(location, routes) {
  const matches = routes.map(route => ({
    ...route,
    match: matchPath(location.pathname || '/', route.path)
  }))

  return matches.find(
    route => route.match && (!route.exact || route.match.isExact)
  )
}

function Menu({ routes, collapsed, location }) {
  const activeItem = findMatchingRoute(location, routes)

  return (
    <nav>
      <AppSidebar
        items={routes.map(route => ({ ...route, to: route.path }))}
        collapsed={collapsed}
        activeItem={activeItem && activeItem.name}
      />
    </nav>
  )
}

export default withRouter(Menu)
