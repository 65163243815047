import React from 'react'
import { Icon } from 'semantic-ui-react'

export const PATH_INIT = '/init'
export const PATH_INIT_CUSTODIANS = '/init/deploy-custodians'
export const PATH_INIT_KEYDOMAINS = '/init/create-key-domains'

const routes = [
  {
    name: 'Dashboard',
    text: 'Übersicht',
    path: '/',
    exact: true,
    component: React.lazy(() => import('./components/Main/views/Dashboard/')),
    icon: <Icon name="th large" />
  },
  {
    name: 'Initialization',
    text: 'Erstinitialisierung',
    path: '/init',
    component: React.lazy(() =>
      import('./components/Main/views/Initialization/')
    ),
    icon: <Icon name="sitemap" />
  },
  {
    name: 'Backup',
    text: 'Backupkarte',
    path: '/backup',
    component: React.lazy(() =>
      import('./components/Main/views/Initialization/Backup')
    ),
    icon: (
      <Icon.Group>
        <Icon name="address card" />
        <Icon corner name="plus" />
      </Icon.Group>
    )
  },
  {
    name: 'CreateChamberCard',
    text: 'Kammerkarte',
    path: '/chamber-card',
    component: React.lazy(() =>
      import('./components/Main/views/CreateChamberCard')
    ),
    icon: <Icon name="id card" />
  },
  {
    name: 'ChamberAuthorization',
    text: 'Authorisierung der Kammer',
    path: '/chamber-auth',
    component: React.lazy(() =>
      import('./components/Main/views/ChamberAuthorization/')
    ),
    icon: <Icon name="id badge" />
  },
  {
    name: 'ChangeKeyCustodians',
    text: 'Verwaltung UAB',
    path: '/change-custodians',
    component: React.lazy(() => import('./components/Main/views/ManageUAB')),
    icon: (
      <Icon.Group>
        <Icon name="users" />
        <Icon corner name="cog" />
      </Icon.Group>
    )
  }
]

export default routes
