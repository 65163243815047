export const LOGIN = '@@app/LOGIN'
export const LOGOUT = '@@app/LOGOUT'
export const TOGGLE_SIDEBAR = '@@app/TOGGLE_SIDEBAR'

export const INIT_START = '@@app/INIT_START'
export const PROGRESS_SETUP_KEY_CUSTODIAN = '@@app/PROGRESS_SETUP_KEY_CUSTODIAN'
export const COMPLETE_SETUP_KEY_CUSTODIAN = '@@app/COMPLETE_SETUP_KEY_CUSTODIAN'
export const SETUP_KEY_CUSTODIAN_DONE = '@@app/SETUP_KEY_CUSTODIAN_DONE'
export const RESET_INIT = '@@app/RESET_INIT'
export const CHAMBER_KEY_GENERATED = '@@app/CHAMBER_KEY_GENERATED'

export const ACKNOWLEDGE = '@@app/ACKNOWLEDGE'
export const BEGIN_ACKNOWLEDGEMENT = '@@app/BEGIN_ACKNOWLEDGEMENT'
export const RESET_ACKNOWLEDGEMENT = '@@app/RESET_ACKNOWLEDGEMENT'

export const START_CHAMBER_AUTHORIZATION = '@@app/START_CHAMBER_AUTHORIZATION'
export const FINISH_CHAMBER_AUTHORIZATION = '@@app/FINISH_CHAMBER_AUTHORIZATION'

export const PEER_DISCONNECTED = '@@app/PEER_DISCONNECTED'
export const SOCKET_RESET = '@@app/SOCKET_RESET'
export const SOCKET_PENDING = '@@app/SOCKET_PENDING'

export const AVAILABLE_PEERLIST = '@@app/AVAILABLE_PEERLIST'

export const WIZARD_READY = '@@app/WIZARD_READY'
export const WIZARD_STATE = '@app/WIZARD_STATE'
export const WIZARD_NEXT = '@@app/WIZARD_NEXT'
export const WIZARD_LAST_PAGE = '@@app/WIZARD_LAST_PAGE'
export const WIZARD_RESET = '@@app/WIZARD_RESET'
export const WIZARD_ACKNOWLEDGE = '@@app/WIZARD_ACKNOWLEDGE'

export const PROGRESS_LIST_CREATE = '@@app/PROGRESS_LIST_CREATE'
export const PROGRESS_LIST_STEP = '@@app/PROGRESS_LIST_STEP'

export const AUDIT_LOG_APPEND = '@@app/AUDIT_LOG_APPEND'

export const SYNC_CUSTODIANS_START = '@@app/SYNC_CUSTODIANS_START'
export const SYNC_CUSTODIANS_DONE = '@@app/SYNC_CUSTODIANS_DONE'
export const SYNC_CUSTODIANS_RESET = '@@app/SYNC_CUSTODIANS_RESET'

export const INIT_NEXT_PAGE = '@@app/INIT_NEXT_PAGE'

export const TOGGLE_AUDIT_SIDEBAR = '@@app/TOGGLE_AUDIT_SIDEBAR'
export const OPEN_AUDIT_SIDEBAR = '@@app/OPEN_AUDIT_SIDEBAR'

export function login(token, username) {
  return {
    type: LOGIN,
    payload: {
      token,
      username
    }
  }
}

export function logout() {
  return {
    type: LOGOUT
  }
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR
  }
}

export function initStart() {
  return {
    type: INIT_START
  }
}

export function resetInit() {
  return {
    type: RESET_INIT
  }
}

export function setupKeyCustodianDone() {
  return {
    type: SETUP_KEY_CUSTODIAN_DONE
  }
}

export function progressSetupKeyCustodian(custodian) {
  return {
    type: PROGRESS_SETUP_KEY_CUSTODIAN,
    payload: {
      custodian
    }
  }
}

export function completeSetupKeyCustodian(custodian) {
  return {
    type: COMPLETE_SETUP_KEY_CUSTODIAN,
    payload: {
      custodian
    }
  }
}

export function chamberKeyGenerated(chamberName) {
  return {
    type: CHAMBER_KEY_GENERATED,
    payload: chamberName
  }
}

export function beginAcknowledgement(operation) {
  return {
    type: BEGIN_ACKNOWLEDGEMENT,
    payload: {
      operation
    }
  }
}

export function acknowledge(operation) {
  return {
    type: ACKNOWLEDGE,
    payload: {
      operation
    }
  }
}

export function resetAcknowledgement() {
  return {
    type: RESET_ACKNOWLEDGEMENT
  }
}

export function startChamberAuthorization(id) {
  return {
    type: START_CHAMBER_AUTHORIZATION,
    payload: id
  }
}

export function finishChamberAuthorization(id) {
  return {
    type: FINISH_CHAMBER_AUTHORIZATION,
    payload: id
  }
}

export function peerDisconnected() {
  return {
    type: PEER_DISCONNECTED
  }
}

export function resetSocket() {
  return {
    type: SOCKET_RESET
  }
}

export function sentRequest(name) {
  return {
    type: SOCKET_PENDING,
    payload: name
  }
}

export function availablePeers(peers) {
  return {
    type: AVAILABLE_PEERLIST,
    payload: peers
  }
}

export function wizardReady() {
  return {
    type: WIZARD_READY
  }
}

export function setWizardState(state) {
  return {
    type: WIZARD_STATE,
    payload: state
  }
}

export function nextWizardPage() {
  return {
    type: WIZARD_NEXT
  }
}

export function lastWizardPage() {
  return {
    type: WIZARD_LAST_PAGE
  }
}

export function acknowledgeWizard() {
  return {
    type: WIZARD_ACKNOWLEDGE
  }
}

export function resetWizard() {
  return {
    type: WIZARD_RESET
  }
}

export function createProgressList(items) {
  return {
    type: PROGRESS_LIST_CREATE,
    payload: items
  }
}

export function progressListStep() {
  return {
    type: PROGRESS_LIST_STEP
  }
}

export function audit(message) {
  return {
    type: AUDIT_LOG_APPEND,
    payload: {
      date: Date.now(),
      message
    }
  }
}

export function startSyncingCustodians(custodians) {
  return {
    type: SYNC_CUSTODIANS_START,
    payload: {
      custodians
    }
  }
}

export function finishSyncingCustodian(custodian) {
  return {
    type: SYNC_CUSTODIANS_DONE,
    payload: {
      custodian
    }
  }
}

export function resetSyncingCustodians() {
  return {
    type: SYNC_CUSTODIANS_RESET
  }
}

export function toggleAuditSidebar() {
  return {
    type: TOGGLE_AUDIT_SIDEBAR,
    _socket: true
  }
}

export function openAuditSidebar() {
  return {
    type: OPEN_AUDIT_SIDEBAR
  }
}
