import React from 'react'
import { name, version } from '../../../package.json'
import { AppFooter } from '@procilon/react-components'
import { Icon } from 'semantic-ui-react'

const links = [
  {
    icon: <Icon name="help circle" />,
    text: 'Hilfe',
    url: 'https://www.procilon.de/support/anfrage'
  },
  {
    text: 'Impressum',
    url: 'https://www.procilon.de/impressum'
  }
]

function Footer() {
  return (
    <footer>
      <AppFooter
        copyrightInfo={
          <>
            {`© ${new Date().getFullYear()} - proNEXT ${name} ist ein Produkt der `}
            <a href="https://www.procilon.de">procilon Group</a>
          </>
        }
        versionInfo={`Version ${version}`}
        links={links}
      />
    </footer>
  )
}

export default Footer
