import React from 'react'
import { Segment, Header } from 'semantic-ui-react'

const NotFound = () => (
  <Segment>
    <Header as="h1">Not found</Header>
  </Segment>
)

export default NotFound
