// const loginToken = sessionStorage.getItem("LoginService::token");
// const username = sessionStorage.getItem("LoginService::username");

export const allCustodians = [
  { name: 'Valentin Huppert', state: undefined, email: 'v.huppert@notar.de' },
  { name: 'Marion Fisch', state: undefined, email: 'm.fisch@notar.de' },
  { name: 'Björn Schreber', state: undefined, email: 'b.schreber@notar.de' },
  { name: 'Benedict Reitter', state: undefined, email: 'b.reitter@notar.de' },
  { name: 'Stella Weis', state: undefined, email: 's.weis@notar.de' },
  { name: 'Silvia Gruber', state: undefined, email: 's.gruber@notar.de' },
  { name: 'Udo Nessler', state: undefined, email: 'u.nessler@notar.de' },
  { name: 'Erhart Schmadel', state: undefined, email: 'e.schmadel@notar.de' },
  { name: 'Finja Klein', state: undefined, email: 'f.klein@notar.de' },
  {
    name: 'Konstanze Mathesius',
    state: undefined,
    email: 'k.mathesius@notar.de'
  },
  { name: 'Karsten Klempner', state: undefined, email: 'k.klempner@notar.de' },
  { name: 'Hildebrandt Wirths', state: undefined, email: 'h.wirths@notar.de' },
  {
    name: 'Klemens Schweiger',
    state: undefined,
    email: 'k.schweiger@notar.de'
  },
  {
    name: 'Waldtraut Fischbacher',
    state: undefined,
    email: 'w.fischbacher@notar.de'
  },
  { name: 'Emily Lehrer', state: undefined, email: 'e.lehrer@notar.de' },
  { name: 'Cilly Hartmann', state: undefined, email: 'c.hartmann@notar.de' },
  { name: 'Mara Söllner', state: undefined, email: 'm.söllner@notar.de' },
  { name: 'Margaretha Esser', state: undefined, email: 'm.esser@notar.de' },
  { name: 'Volkhardt Speidel', state: undefined, email: 'v.speidel@notar.de' },
  { name: 'Anatol Wertheim', state: undefined, email: 'a.wertheim@notar.de' }
]

const custodians = allCustodians.slice(0, 10)

const loginToken = 'token'

export const randomUser = users =>
  users[Math.floor(Math.random() * users.length)]
export const NOBODY = 'Warte...'
export const ALL_USERNAMES = custodians.map(c => c.name)

const initialState = {
  auth: {
    loginToken,
    username: NOBODY
  },
  sidebarCollapsed: false,
  initState: undefined,
  acknowledgements: {
    inProgress: false,
    auditorAck: false,
    operation: null
  },
  custodians,
  chambers: [
    { name: 'Sachsen', state: undefined },
    { name: 'Celle', state: undefined },
    { name: 'Schleswig-Holstein', state: undefined },
    { name: 'Bremen', state: undefined },
    { name: 'Hamburg', state: undefined },
    { name: 'Oldenburg', state: undefined },
    { name: 'Brandenburg', state: undefined },
    { name: 'Westfalen', state: undefined },
    { name: 'Braunschweig', state: undefined },
    { name: 'Sachsen-Anhalt', state: undefined },
    { name: 'Berlin', state: undefined },
    { name: 'Mecklenburg-Vorpommern', state: undefined },
    { name: 'Rheinland', state: undefined },
    { name: 'Kassel', state: undefined },
    { name: 'Thüringen', state: undefined },
    { name: 'Koblenz', state: undefined },
    { name: 'Frankfurt am Main', state: undefined },
    { name: 'Saarland', state: undefined },
    { name: 'Pfalz', state: undefined },
    { name: 'Baden-Württemberg', state: undefined },
    { name: 'Bayern', state: undefined }
  ],
  socket: {},
  chambersToAuth: [
    {
      id: '0815',
      name: 'Notarkammer Sachsen',
      inProgress: false,
      finished: false
    },
    {
      id: '0816',
      name: 'Notarkammer Berlin',
      inProgress: false,
      finished: false
    }
  ],
  custodianSyncState: 'stopped',
  custodiansToSync: [],
  auditSidebarOpen: false
}

export default initialState
