export const NEXTPAGE = '@@app/cardinit/NEXTPAGE'
export const CUSTODIANS_BEGIN = '@@app/cardinit/CUSTODIANS_BEGIN'
export const CUSTODIAN_START = '@@app/cardinit/CUSTODIAN_START'
export const CUSTODIAN_FINISH = '@@app/cardinit/CUSTODIAN_FINISH'
export const CUSTODIANS_DONE = '@@app/cardinit/CUSTODIANS_DONE'
export const BACKUP_MODE = '@@app/cardinit/BACKUP_MODE'
export const RESET = '@@app/cardinit/RESET'
export const SET_DKEK = '@@app/cardinit/SET_DKEK'

export function nextPage() {
  return {
    type: NEXTPAGE
  }
}

export function beginCustodianImport(custodians) {
  return {
    type: CUSTODIANS_BEGIN,
    payload: {
      custodians
    }
  }
}

export function startCustodianImport(name) {
  return {
    type: CUSTODIAN_START,
    payload: {
      name
    }
  }
}

export function finishCustodianImport(name) {
  return {
    type: CUSTODIAN_FINISH,
    payload: {
      name
    }
  }
}

export function importCustodiansDone() {
  return {
    type: CUSTODIANS_DONE
  }
}

export function reset() {
  return {
    type: RESET
  }
}

export function backupMode() {
  return {
    type: BACKUP_MODE
  }
}

export function setDkek(dkek) {
  return {
    type: SET_DKEK,
    payload: {
      dkek
    }
  }
}
