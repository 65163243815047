import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { send } from './websocket-connect'
import app from './reducer'

let allowNavigation = false

export const history = createBrowserHistory({
  getUserConfirmation(message, callback) {
    const { app } = store.getState()
    const mayNavigate =
      !app || !app.socket.peerId || app.socket.initiator || allowNavigation
    callback(mayNavigate)
    allowNavigation = false
  }
})
history.block('')

export const store = createStore(
  app(history),
  composeWithDevTools(
    applyMiddleware(socketMiddleware, routerMiddleware(history))
  )
)

function socketMiddleware(store) {
  return next => action => {
    const { app } = store.getState()
    if (!app) {
      return next(action)
    }
    if (app.socket.initiator && !action._socket) {
      console.log('dispatch to websocket', action)
      send(JSON.stringify(action))
    }
    if (action.type === '@@router/LOCATION_CHANGE') {
      allowNavigation = !!action._socket
    }

    if (app.socket.peerId && !app.socket.initiator && !action._socket) {
      return
    }
    return next(action)
  }
}
