import initialState from './initial-state'
import {
  LOGIN,
  LOGOUT,
  TOGGLE_SIDEBAR,
  INIT_START,
  PROGRESS_SETUP_KEY_CUSTODIAN,
  COMPLETE_SETUP_KEY_CUSTODIAN,
  RESET_INIT,
  SETUP_KEY_CUSTODIAN_DONE,
  CHAMBER_KEY_GENERATED,
  BEGIN_ACKNOWLEDGEMENT,
  ACKNOWLEDGE,
  START_CHAMBER_AUTHORIZATION,
  FINISH_CHAMBER_AUTHORIZATION,
  PEER_DISCONNECTED,
  SOCKET_RESET,
  AVAILABLE_PEERLIST,
  WIZARD_NEXT,
  WIZARD_RESET,
  WIZARD_READY,
  WIZARD_STATE,
  RESET_ACKNOWLEDGEMENT,
  WIZARD_ACKNOWLEDGE,
  WIZARD_LAST_PAGE,
  PROGRESS_LIST_CREATE,
  PROGRESS_LIST_STEP,
  SOCKET_PENDING,
  AUDIT_LOG_APPEND,
  audit,
  SYNC_CUSTODIANS_START,
  SYNC_CUSTODIANS_DONE,
  SYNC_CUSTODIANS_RESET,
  TOGGLE_AUDIT_SIDEBAR,
  OPEN_AUDIT_SIDEBAR
} from './actions'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { toastsReducer as toasts } from 'react-toastify-redux'
import produce from 'immer'
import {
  auditNavigation,
  auditAuditorAck,
  requestAuditorAck
} from './auditMessages'
import routes from '../routes'
import cardInitialization from './card-initialization/reducer'
import chamberCardCreation from './chamber-card-creation/reducer'
import chambercardAuthorization from './chambercard-authorization/reducer'
import { LOCATION_CHANGE } from 'connected-react-router'

export default history =>
  combineReducers({
    router: connectRouter(history),
    toasts,
    app,
    wizard,
    progressList,
    auditLog,
    cardInitialization,
    chamberCardCreation,
    chambercardAuthorization
  })

function auditLog(state = [], action = {}) {
  switch (action.type) {
    case AUDIT_LOG_APPEND:
      return [...state, action.payload]
    case LOCATION_CHANGE:
      if (state.length === 0) {
        return state
      }
      const route = routes.find(
        r => r.path === action.payload.location.pathname
      )
      if (!route) return state

      return auditLog(state, audit(auditNavigation(route.text)))
    case ACKNOWLEDGE:
      return auditLog(state, audit(auditAuditorAck(action.payload.operation)))
    case BEGIN_ACKNOWLEDGEMENT:
      return auditLog(state, audit(requestAuditorAck(action.payload.operation)))
    default:
      return state
  }
}

function progressList(state = { items: [] }, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case PROGRESS_LIST_CREATE:
        draft.items = action.payload
        return
      case PROGRESS_LIST_STEP:
        for (const item of draft.items) {
          if (!item.status) {
            item.status = 'loading'
            return
          }
          if (item.status === 'loading') {
            item.status = 'done'
            return
          }
          if (item.items) {
            const original = item.items
            item.items = progressList({ items: item.items }, action).items
            if (original !== item.items) {
              return
            }
          }
        }
        return
      default:
    }
  })
}

function wizard(state = { page: 0 }, action = {}) {
  switch (action.type) {
    case WIZARD_NEXT:
      return {
        ...state,
        page: state.page + 1,
        ready: false
      }
    case WIZARD_LAST_PAGE:
      return {
        ...state,
        lastPage: true
      }
    case WIZARD_READY: {
      return {
        ...state,
        ready: true
      }
    }
    case WIZARD_STATE: {
      return {
        ...state,
        state: action.payload
      }
    }
    case WIZARD_RESET:
      return {
        page: 0,
        ready: false
      }
    case WIZARD_ACKNOWLEDGE:
      return {
        ...state,
        acknowledged: true
      }
    default:
      return state
  }
}

function socket(state = {}, action = {}) {
  switch (action.type) {
    case 'SOCKET_ACCEPTED':
      if (action.payload.initiator) {
        return {
          ...state,
          ...action.payload
        }
      } else {
        return {
          id: state.id,
          peerName: state.requestedPeerName,
          peerId: state.requestedPeerId
        }
      }
    case 'SOCKET_SESSION_ID':
      return {
        id: action.payload
      }
    case 'SOCKET_REQUESTED':
      return {
        ...state,
        requestedPeerName: action.payload.peerName,
        requestedPeerId: action.payload.peerId
      }
    case SOCKET_PENDING:
      return {
        ...state,
        pendingUser: action.payload
      }
    default:
      return state
  }
}

function app(state = initialState, action = {}) {
  if (action.type === LOGOUT) {
    return initialState
  }

  const produced = produce(state, draft => {
    draft.socket = socket(state && state.socket, action)
    switch (action.type) {
      case LOGIN:
        draft.auth = {
          loginToken: action.payload.token,
          username: action.payload.username
        }
        return
      case TOGGLE_SIDEBAR:
        draft.sidebarCollapsed = !state.sidebarCollapsed
        return
      case PROGRESS_SETUP_KEY_CUSTODIAN:
        draft.custodians.find(
          c => c.name === action.payload.custodian.name
        ).state = 'loading'
        return
      case COMPLETE_SETUP_KEY_CUSTODIAN:
        draft.custodians.find(
          c => c.name === action.payload.custodian.name
        ).state = 'done'
        return
      case INIT_START:
        draft.initState = 'setup-custodians'
        return
      case RESET_INIT:
        draft.initState = initialState.initState
        draft.custodians = initialState.custodians
        return
      case SETUP_KEY_CUSTODIAN_DONE:
        draft.initState = 'setup-custodians-done'
        return
      case CHAMBER_KEY_GENERATED:
        draft.chambers.find(chamber => chamber.name === action.payload).state =
          'done'
        return
      case BEGIN_ACKNOWLEDGEMENT:
        draft.acknowledgements.inProgress = true
        draft.acknowledgements.auditorAck = false
        draft.acknowledgements.operation = action.payload.operation
        return
      case ACKNOWLEDGE:
        if (state.acknowledgements.inProgress) {
          draft.acknowledgements.auditorAck = true
        }
        return
      case RESET_ACKNOWLEDGEMENT:
        draft.acknowledgements.inProgress = false
        draft.acknowledgements.auditorAck = false
        return
      case START_CHAMBER_AUTHORIZATION:
        if (state.chambersToAuth.some(chamber => chamber.inProgress)) {
          return
        }
        draft.chambersToAuth.find(
          chamber => chamber.id === action.payload
        ).inProgress = true
        return
      case FINISH_CHAMBER_AUTHORIZATION: {
        const chamber = draft.chambersToAuth.find(
          chamber => chamber.id === action.payload && chamber.inProgress
        )
        if (chamber) {
          chamber.inProgress = false
          chamber.finished = true
          draft.acknowledgements.inProgress = false
          draft.acknowledgements.auditorAck = false
        }
        return
      }
      case PEER_DISCONNECTED:
        draft.socket = {
          id: state.socket.id
        }
        return
      case SOCKET_RESET:
        draft.socket = initialState.socket
        return
      case AVAILABLE_PEERLIST:
        draft.socket.availablePeers = action.payload
        return
      case SYNC_CUSTODIANS_START:
        draft.custodianSyncState = 'started'
        draft.custodiansToSync = action.payload.custodians
        return
      case SYNC_CUSTODIANS_DONE:
        if (action.payload.custodian) {
          draft.custodiansToSync = draft.custodiansToSync.filter(
            c => c !== action.payload.custodian
          )
        } else {
          draft.custodianSyncState = 'done'
        }
        return
      case SYNC_CUSTODIANS_RESET:
        draft.custodianSyncState = 'stopped'
        draft.custodiansToSync = []
        return
      case TOGGLE_AUDIT_SIDEBAR:
        draft.auditSidebarOpen = !state.auditSidebarOpen
        return
      case OPEN_AUDIT_SIDEBAR:
        draft.auditSidebarOpen = true
        return
      default:
        return
    }
  })

  return produced
}
