import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Sidebar,
  Label,
  Icon,
  Dropdown,
  Table,
  Button,
  Segment
} from 'semantic-ui-react'
import { TabbedNavigation, CircleIcon } from '@procilon/react-components'

import './index.less'
import { send, reset } from 'state/websocket-connect'
import { sentRequest } from 'state/actions'
import { toComponent } from 'state/auditMessages'

function IncomingAuditRequest() {
  const state = useSelector(state => state.app.socket)
  const username = useSelector(state => state.app.auth.username)

  const custodians = useSelector(state => state.app.custodians)

  function accept() {
    send(
      `negotiation:${JSON.stringify({
        type: 'ACCEPT',
        peerId: state.requestedPeerId,
        data: username
      })}`
    )
  }

  if (state.requestedPeerName) {
    return (
      <div className="audit-request">
        <div>
          Bitte um Audit am {new Date().toLocaleDateString('de')} -{' '}
          {new Date().toLocaleTimeString('de')}
        </div>
        <Segment>
          <Button icon basic>
            <Icon name="close" />
          </Button>
          <CircleIcon content="WS" />
          <strong>{state.requestedPeerName}</strong>
          <div className="meta">
            {custodians.find(c => c.name === state.requestedPeerName).email}
          </div>
          <Button color="purple" onClick={accept}>
            Audit starten
          </Button>
          <Button basic className="tertiary">
            ablehnen
          </Button>
        </Segment>
      </div>
    )
  } else {
    return null
  }
}

function AuditLogItem({ time, content }) {
  return (
    <div>
      <span>{new Date(time).toLocaleTimeString()}</span>
      <span>{content}</span>
    </div>
  )
}

function AuditLog({ name }) {
  const initiator = useSelector(state => state.app.socket.initiator)
  const log = [...useSelector(state => state.auditLog)].reverse()
  useEffect(() => {
    if (!initiator) {
      document.body.classList.add('audit')
    }
  })
  return (
    <>
      <ConnectInfo name={name} />
      <div className="audit-log">
        <div>
          <Button basic>
            <Icon name="calendar alternate" style={{ marginRight: '21px' }} />
            Protokoll herunterladen
          </Button>
        </div>
        {log.map(entry => (
          <AuditLogItem
            time={entry.date}
            content={toComponent(entry.message)}
          />
        ))}
      </div>
    </>
  )
}

function ConnectInfo({ name }) {
  return (
    <div className="connect-info">
      <strong>{name}</strong>
      <span>verbunden</span>
      <Icon name="close" inverted link size="large" onClick={reset} />
    </div>
  )
}

function PendingConnection({ name }) {
  return (
    <div className="connect-pending">
      <Icon loading name="circle notch" />
      <strong>{name}</strong>
      <span>warte...</span>
      <Icon name="close" link size="large" />
    </div>
  )
}

function ConnectSelection({ custodians, onSelect }) {
  const options = custodians.map(custodian => ({
    key: custodian.name,
    text: custodian.name,
    value: custodian
  }))

  useEffect(() => {
    document.body.classList.remove('audit')
  })

  return (
    <Dropdown
      selection
      className="auditor-select"
      placeholder={
        <>
          <Icon name="eye" /> Verwaltungsberechtigten auswählen
        </>
      }
      options={options}
      onChange={onSelect}
    />
  )
}

function AuditNotConnected() {
  const dispatch = useDispatch()
  const custodians = useSelector(state =>
    (state.app.socket.availablePeers || [])
      .filter(c => !c.busy)
      .filter(c => c.name !== state.app.auth.username)
  )
  const username = useSelector(state => state.app.auth.username)

  return (
    <>
      <ConnectSelection
        custodians={custodians}
        onSelect={(_, { value }) => {
          send(
            `negotiation:${JSON.stringify({
              type: 'REQUEST',
              peerId: value.id,
              data: username
            })}`
          )
          dispatch(sentRequest(value.name))
        }}
      />
    </>
  )
}

function AuditHistory() {
  return (
    <div className="audit-history">
      <h1>Letzte Audits</h1>
      <Table basic>
        <Table.Body>
          <Table.Row>
            <Table.Cell>07-04-2019</Table.Cell>
            <Table.Cell>James Avery</Table.Cell>
            <Table.Cell>
              <Button basic color="blue" className="tertiary">
                Protokoll <Icon name="file pdf" />
              </Button>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>08-10-2019</Table.Cell>
            <Table.Cell>Joseph Marcell</Table.Cell>
            <Table.Cell>
              <Button basic color="blue" className="tertiary">
                Protokoll <Icon name="file pdf" />
              </Button>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>01-19-2019</Table.Cell>
            <Table.Cell>Alfonso Ribeiro</Table.Cell>
            <Table.Cell>
              <Button basic color="blue" className="tertiary">
                Protokoll <Icon name="file pdf" />
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

function AuditSidebar() {
  const state = useSelector(state => state.app.socket)
  const visible = useSelector(state => state.app.auditSidebarOpen)

  return (
    <div className={`audit-sidebar ${visible ? 'visible' : ''}`}>
      <Sidebar direction="right" visible>
        <TabbedNavigation
          items={[
            // {
            //   name: 'chat',
            //   content: 'CHAT',
            //   onClick: () => setAuditMenuOpen(false),
            //   disabled: true,
            //   label: <Label color="purple">2</Label>
            // },
            {
              name: 'audit',
              content: 'AUDIT',
              label: state.requestedPeerName && <Label color="purple">1</Label>
            }
          ]}
          activeItem={'audit'}
        />
        {state.peerId ? (
          <AuditLog name={state.peerName} />
        ) : state.pendingUser ? (
          <PendingConnection name={state.pendingUser} />
        ) : (
          <AuditNotConnected />
        )}
        <IncomingAuditRequest />
        <AuditHistory />
      </Sidebar>
    </div>
  )
}

export default AuditSidebar
