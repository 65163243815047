import initialState from './initial-state'
import {
  NEXT_STAGE,
  GOTO_STAGE,
  RESET,
  CHOOSE_CHAMBER,
  WAIT_FOR_APPROVAL
} from './actions'

export default function(
  state = initialState,
  action = { type: '', payload: undefined }
) {
  switch (action.type) {
    case RESET:
      return initialState
    case NEXT_STAGE:
      return {
        ...state,
        stage: nextStage(state.stage)
      }
    case GOTO_STAGE:
      return {
        ...state,
        stage: action.payload.stage
      }
    case CHOOSE_CHAMBER:
      return {
        ...state,
        chamber: action.payload.chamber
      }
    case WAIT_FOR_APPROVAL:
      return {
        ...state,
        waitForApproval: true
      }
    default:
      return state
  }
}

function nextStage(currentStage) {
  if (currentStage === 'cards-present') return 'start-audit'
  if (currentStage === 'start-audit') return 'choose-chamber'
  if (currentStage === 'choose-chamber') return 'display-custodians'
  if (currentStage === 'display-custodians') return 'setup-custodians'
  if (currentStage === 'setup-custodians') return 'setup-card-system'
  if (currentStage === 'sign-device-id') return 'setup-card-system'
  if (currentStage === 'setup-card-system') return 'display-protocol'
  return 'nyi'
}
