import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Message, Icon, Segment, Button } from 'semantic-ui-react'
import {
  acknowledge as ack,
  resetAcknowledgement,
  beginAcknowledgement
} from 'state/actions'
import { send } from 'state/websocket-connect'
import Floater from 'react-floater'

export function AckRequest({ initiator, operation, auditorName, acknowledge }) {
  if (initiator) {
    return (
      <Segment padded>
        <Icon size="big" loading name="circle notch" /> Warte auf Bestätigung
        durch {auditorName}
      </Segment>
    )
  } else {
    return (
      <Floater
        content={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>{operation}</p>
            <Button color="purple" floated="right" onClick={acknowledge}>
              Bestätigen
            </Button>
          </div>
        }
        open
      >
        <Segment padded>
          <Icon size="big" loading name="circle notch" /> Warte auf Bestätigung
          durch {auditorName}
        </Segment>
      </Floater>
    )
  }
}

function AcknowledgementProgress({ done, fake, acknowledge, operation }) {
  const state = useSelector(state => ({
    acknowledgements: state.app.acknowledgements,
    initiator: state.app.socket.initiator,
    auditorName: state.app.socket.initiator
      ? state.app.socket.peerName
      : state.app.auth.username
  }))

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(beginAcknowledgement(operation))
    return function cleanup() {
      dispatch(resetAcknowledgement())
    }
  }, [dispatch, operation])

  return (
    <>
      {fake || state.acknowledgements.auditorAck ? (
        <>
          <Message success>
            <Icon color="green" name="check" />
            Bestätigt durch {state.auditorName}
          </Message>
          {typeof done === 'function' ? done() : done}
        </>
      ) : (
        <AckRequest
          acknowledge={acknowledge}
          auditorName={state.auditorName}
          initiator={state.initiator}
          operation={operation}
        />
      )}
    </>
  )
}

function useAudit(operation, fake) {
  const state = useSelector(state => ({
    connected: fake || !!state.app.socket.peerId,
    acknowledgements: state.app.acknowledgements,
    initiator: state.app.socket.initiator,
    peerId: state.app.socket.peerId
  }))

  const dispatch = useDispatch()

  function acknowledge() {
    if (state.initiator) {
      dispatch(ack(operation))
    } else {
      send('ack:' + state.peerId)
    }
  }

  return {
    state,
    cancel() {
      console.trace('cancel')
    },
    acknowledge,
    progress(done) {
      return (
        <AcknowledgementProgress
          done={done}
          fake={fake}
          acknowledge={acknowledge}
          operation={operation}
        />
      )
    },
    render(element) {
      return state.connected || fake ? (
        element
      ) : (
        <Message error>
          <p>Diese Operation muss unter Aufsicht durchgeführt werden.</p>
          <p>
            Bitte wählen sie im Audit-Bereich auf der rechten Seite einen
            Auditor aus.
          </p>
        </Message>
      )
    }
  }
}

export default useAudit
