import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { connect, Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify-redux'

import Header from './components/Header/Header'
import Menu from './components/Menu/Menu'
import Main from './components/Main/Main'
import Footer from './components/Footer/Footer'

import 'react-toastify/dist/ReactToastify.css'
// import './semantic-ui/imports.less'
import 'semantic-ui-less/semantic.less'
import './App.less'
import './Toast.less'

import routes from './routes'
import { store, history } from 'state/store'
import AuditSidebar from 'components/AuditSidebar'

function RoutedApp({ loggedIn, sidebarCollapsed }) {
  if (!loggedIn) {
    // window.location.replace(`/login?applicationUrl=${window.location.href}`)
    return <div />
  } else {
    return (
      <ConnectedRouter history={history}>
        <div className="App">
          <Header />
          <Menu routes={routes} collapsed={sidebarCollapsed} />
          <div className="App-content">
            <Main routes={routes} />
            <Footer />
          </div>
          <AuditSidebar />
          <ToastContainer autoClose={false} />
        </div>
      </ConnectedRouter>
    )
  }
}

const mapStateToProps = state => ({
  loggedIn: !!state.app.auth.loginToken,
  sidebarCollapsed: state.app.sidebarCollapsed
})
const ConnectedApp = connect(mapStateToProps)(RoutedApp)

function App() {
  return (
    <Provider store={store}>
      <ConnectedApp />
    </Provider>
  )
}

export default App
